/* eslint-disable */ 
module.exports = {
    //测试环境
    // IS_DEBUG:true,
    // BASE_API: 'https://testserver.1848q.com',
    // SignalR: 'https://stock.xydkit.com/ws',
    // WEB_DOMAIN:'https://test.1848q.com',//web切换H5
    // WS_TRADE:'wss://tradewss.1848q.com:9081/ws/v2/trade?token=eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJ0YWMgYmFja2VuZCIsImFjY291bnRfaWQiOiIxNjY2MjU4MCIsInBhc3N3b3JkIjoiYWJjZGVmMDAwIn0.qfKG_HckC5vbwtOSQIpyLahWzfNw9wem3TcnDWHF1rJRuAGjSxGbJ2hOOgkadhjXrg-ce7hrVpepWIjtu8n_Gg',//交易ws地址

    // IM_API: "https://im.1848q.com:8090",//IM 请求API 变更请到utils/request修改
    // IM_SOCKET: 'wss://im.1848q.com:8090/wss/default.io',//IM ws变更请到layout/HoverMenu设置wsURL
    // SERVICE_ID: 19,//客服ID
    // SYMBOL:"i2501",//tradingview 默认合约
    // WX_APPID:'wx3241732659e4808a',//微信appid
    // WX_LOGIN_DOMAIN:'https://test.1848q.com',//微信授权回调域名
    // H5_DOMAIN:'https://m.1848q.com',//web切换H5


    //正式
    IS_DEBUG:false,
    BASE_API: 'https://server.1848q.com',
    SignalR: 'https://stock.xydkit.com/ws',
    WEB_DOMAIN: 'https://1848q.com',

    WS_TRADE: 'wss://tradewss.1848q.com:9081/ws/v2/trade?token=eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJ0YWMgYmFja2VuZCIsImFjY291bnRfaWQiOiIxNjY2MjU4MCIsInBhc3N3b3JkIjoiYWJjZGVmMDAwIn0.qfKG_HckC5vbwtOSQIpyLahWzfNw9wem3TcnDWHF1rJRuAGjSxGbJ2hOOgkadhjXrg-ce7hrVpepWIjtu8n_Gg',//交易ws地址
    IM_API: "https://im.1848q.com:8090",//IM 请求API 变更请到utils/request修改
    IM_SOCKET: 'wss://im.1848q.com:8090/wss/default.io',//IM ws变更请到layout/HoverMenu设置wsURL
    SERVICE_ID: 19,//客服ID
    SYMBOL: "i2501",//tradingview 默认合约
    WX_APPID: 'wx3241732659e4808a',//微信appid
    WX_LOGIN_DOMAIN: 'https://www.1848q.com',//微信授权回调域名
    H5_DOMAIN: 'https://m.1848q.com',//web切换H5

}